/* Smooth transition for app-like feel */
body.mobile {
	transition: all 0.3s ease-in-out;
}

.games_full_background.mobile {
	width: 100% !important;
	height: 100% !important;
	left: 0;
	top: 0;
	position: absolute;
}

.preview.mobile {
	overflow: hidden;
}

.games .games_screen_background.mobile .games_loader {
	border: none;
}

.games .games_screen_background.mobile .items {
	border: none;
}

.games .games_screen_background.mobile {
	overflow: visible !important;
}

.games .items .games_screen_background.mobile,
.preview.mobile,
.game-editor_main.mobile {
	.text-item {
		&_text {
			font-size: 2em;
		}
	}

	.ql-editor {
		//desktop waardes: .75em, 1.5em, 2.5em, 4.5em (normal 1em)
		.ql-size-small {
			font-size: 0.75em;
		}
		.ql-size-large {
			font-size: 1.5em;
		}
		.ql-size-xl {
			font-size: 2.5em;
		}
		.ql-size-xxl {
			font-size: 4.5em;
		}
	}

	.popup-item {
		&_cross {
			.icon {
				height: 3.4em;
			}
		}
		&_text {
			font-size: 2em;
		}
		&_button {
			.btn {
				font-size: 1.6em;
				padding: 0.6em 1.6em;
				margin: 0.5em 0 0.5em 0;
			}
		}
	}

	.login-item {
		min-height: 26em;
		min-width: 50em;

		&_text {
			font-size: 2em;
			margin: 0.5em 0.5em 0 0.5em;
		}
		&_nickname {
			font-size: 2em;
		}
		&_logo {
			.logo_img {
				width: 5.2em;
			}

			.logo_text {
				font-size: 1.2em;
			}
		}
		&_btn {
			.btn {
				font-size: 1.6em;
				padding: 0.6em 1.6em;
			}
		}
	}

	.button-item {
		.btn {
			font-size: 2em;
		}
	}

	.input-item {
		.input_text-field {
			font-size: 3.2em;
		}
		.btn {
			font-size: 1.6em;
			padding: 0.6em 1.6em;
		}
	}

	.counter-item_score {
		font-size: 3.5em;
	}

	.feedback-item_feedback {
		font-size: 2em;
	}

	.highscores-item {
		&_intro {
			font-size: 2em;
		}

		&_highscore-container {
			font-size: 2em;
		}
	}
}

.item_sticky {
	display: grid;
	grid-template-columns: 3rem 3rem 3rem;
	grid-template-rows: 3rem 3rem 3rem;
	gap: 2px;
	justify-self: center;
	padding: 5px;
	border: 2px solid var(--color-primary-light);
	border-radius: 15px;
	margin-top: -2.4rem !important;

	.sticky_arrow {
		.icon {
			fill: var(--color-grey-light);
			stroke: var(--color-grey-light);
		}

		.icon:hover {
			fill: var(--color-primary);
			stroke: var(--color-primary);
			cursor: pointer;
		}
	}

	.settings {
		&_sticky-top-left {
			&.active {
				.icon {
					fill: var(--color-primary);
					stroke: var(--color-primary);
					stroke-width: 2px;
					height: 3rem;
				}
			}
		}

		&_sticky-top {
			transform: rotate(45deg);

			&.active {
				.icon {
					fill: var(--color-primary);
					stroke: var(--color-primary);
					stroke-width: 2px;
					height: 3rem;
				}
			}
		}
		&_sticky-top-right {
			transform: rotate(90deg);

			&.active {
				.icon {
					fill: var(--color-primary);
					stroke: var(--color-primary);
					stroke-width: 2px;
					height: 3rem;
				}
			}
		}
		&_sticky-right {
			transform: rotate(135deg);

			&.active {
				.icon {
					fill: var(--color-primary);
					stroke: var(--color-primary);
					stroke-width: 2px;
					height: 3rem;
				}
			}
		}
		&_sticky-bottom-right {
			transform: rotate(180deg);

			&.active {
				.icon {
					fill: var(--color-primary);
					stroke: var(--color-primary);
					stroke-width: 2px;
					height: 3rem;
				}
			}
		}
		&_sticky-bottom {
			transform: rotate(225deg);

			&.active {
				.icon {
					fill: var(--color-primary);
					stroke: var(--color-primary);
					stroke-width: 2px;
					height: 3rem;
				}
			}
		}
		&_sticky-bottom-left {
			transform: rotate(270deg);

			&.active {
				.icon {
					fill: var(--color-primary);
					stroke: var(--color-primary);
					stroke-width: 2px;
					height: 3rem;
				}
			}
		}
		&_sticky-left {
			transform: rotate(315deg);

			&.active {
				.icon {
					fill: var(--color-primary);
					stroke: var(--color-primary);
					stroke-width: 2px;
					height: 3rem;
				}
			}
		}
		&_sticky-none {
			min-width: 3rem;
			min-height: 3rem;
			background-color: var(--color-primary-very-light);
			border-radius: 50%;

			.icon {
				display: none;
			}
		}
	}
}

.games .game-login.mobile {
	display: flex;
	flex-direction: column;

	.game-login {
		display: flex;
		flex-direction: column;
	}

	.game-login_game-name {
		background-color: transparent;
		margin-bottom: 2rem;

		.game-name_welcome {
			font-size: 2.5rem;
			padding-top: 1rem;
		}

		.game-name_name {
			font-size: 4.5rem;
		}
	}

	.game-login_name-bar {
		background-color: transparent;
		height: 10rem;
	}

	.game-login_game-password {
		height: auto;

		.game-password_card {
			min-height: fit-content;

			.game-password_header {
				display: none;
			}

			.game-password_input {
				margin-bottom: 3rem;
			}

			.game-password_contact {
				display: none;
			}
		}
	}
}
